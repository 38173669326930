@font-face {
  font-family: ExpoArabic;
  font-weight: 400px;
  src: url("./assets/ExpoArabic-Book.ttf");
}
body {
  overflow-x: hidden !important;
  font-family: ExpoArabic !important;
  background-color: whitesmoke;
}

nav .navImage {
  width: 25% !important;
}
nav .navImage img {
  width: 100% !important;
}
nav ul li h4 {
  cursor: pointer;
}

#nav2 {
  background-color: #417920;
}
#nav2 ul li a {
  color: white !important;
  font-size: 22px;
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
#nav2 ul li a:hover {
  color: rgb(255, 42, 42) !important;
  text-decoration: underline;
}

#nav1 {
  background-color: #1A1A1A !important;
}
#nav1 ul li h6, #nav1 ul li a {
  color: #70BE44 !important;
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  text-align: center;
  cursor: pointer;
}
#nav1 ul li h6:hover, #nav1 ul li a:hover {
  color: #166606 !important;
  text-decoration: underline;
  text-decoration-color: #70BE44 !important;
}

#Home .slick-dots {
  top: 90% !important;
  left: 42% !important;
}
#Home .slick-dots button::before {
  font-size: 16px !important;
  color: white !important;
}
#Home .slick-dots .slick-active {
  color: #166606 !important;
}
#Home .slick-next {
  right: 2% !important;
  color: #166606 !important;
  border-radius: 8px !important;
  z-index: 9999999;
}
#Home .slick-prev {
  left: 2% !important;
  color: #166606 !important;
  border-radius: 8px !important;
  z-index: 9999999;
}

#AboutUs h2 {
  font-size: 50px;
  position: relative;
  color: #70BE44;
}
#AboutUs h2::after {
  content: "";
  width: 30%;
  height: 5px;
  position: absolute;
  background-color: #70BE44;
  margin: auto;
  left: 0%;
  right: 0%;
  top: 105%;
}
#AboutUs p {
  font-size: 24px;
}

#OurServices h2 {
  color: #70BE44 !important;
  font-size: 50px;
  position: relative;
}
#OurServices h2::after {
  content: "";
  width: 15%;
  height: 5px;
  position: absolute;
  background-color: #70BE44;
  margin: auto;
  left: 0%;
  right: 0%;
  top: 105%;
}
#OurServices p {
  color: rgb(0, 0, 0);
  font-size: 24px;
}

#OurDatesJourney h2 {
  font-size: 50px;
  color: #70BE44 !important;
  position: relative;
}
#OurDatesJourney h2::after {
  content: "";
  width: 60%;
  height: 5px;
  position: absolute;
  background-color: #70BE44 !important;
  left: 0%;
  right: 0%;
  margin: auto;
  top: 105%;
}
#OurDatesJourney p {
  font-size: 24px;
}
#OurDatesJourney h3 {
  color: #70BE44 !important;
  font-size: 35px;
  font-weight: 600;
}

#OurGallery h2 {
  font-size: 50px;
  position: relative;
  color: #70BE44 !important;
}
#OurGallery h2::after {
  content: "";
  position: absolute;
  top: 115%;
  bottom: 0%;
  right: 0%;
  left: 0%;
  width: 15%;
  height: 5px;
  margin: auto;
  background-color: #70BE44;
}
#OurGallery .editIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #166606;
}

.categoryLinksContainer {
  width: 100%;
  margin: auto;
}

#footer {
  border-top: 5px solid #70BE44;
  background-color: #4C4C4C;
}
#footer h3 {
  font-size: 25px;
  position: relative;
  margin-bottom: 30px !important;
  color: #70BE44 !important;
}
#footer h3::after {
  content: "";
  position: absolute;
  top: 115%;
  bottom: 0%;
  right: 0%;
  left: 0%;
  width: 15%;
  height: 5px;
  margin: auto;
  background-color: #70BE44;
}
#footer #footerContact div p {
  color: white !important;
}
#footer .footerIcons {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(113, 190, 68, 0.4549019608);
  color: white;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#footer #footerMainLinks h6 {
  cursor: pointer;
  color: white;
}
#footer #footerMainLinks a {
  color: white !important;
}

#OurMissinAndOurVision h2 {
  font-size: 45px;
  font-weight: 600;
  color: #70BE44 !important;
  margin-bottom: 30px;
  position: relative;
}
#OurMissinAndOurVision h2::after {
  content: "";
  position: absolute;
  top: 115%;
  bottom: 0%;
  right: 0%;
  left: 0%;
  width: 15%;
  height: 5px;
  margin: auto;
  background-color: #70BE44;
}
#OurMissinAndOurVision p {
  font-size: 20px;
  font-weight: 400;
}

#ContactUs h2 {
  font-size: 45px;
  font-weight: 600;
  color: #70BE44 !important;
  margin-bottom: 30px;
  position: relative;
}
#ContactUs h2::after {
  content: "";
  position: absolute;
  top: 115%;
  bottom: 0%;
  right: 0%;
  left: 0%;
  width: 15%;
  height: 5px;
  margin: auto;
  background-color: #70BE44;
}

#specificCategory h2 {
  font-size: 45px;
  font-weight: 600;
  color: #70BE44 !important;
  margin-bottom: 30px;
  position: relative;
}
#specificCategory h2::after {
  content: "";
  position: absolute;
  top: 115%;
  bottom: 0%;
  right: 0%;
  left: 0%;
  width: 15%;
  height: 5px;
  margin: auto;
  background-color: #70BE44;
}

.categoryProductsSize h3 {
  font-size: 16px !important;
}

.containerBox {
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.containerBox:hover {
  transform: scale(1.1, 1.1);
}

body, * {
  scroll-behavior: unset !important;
}

@media screen and (max-width: 800px) {
  #footer p {
    font-size: 11px;
  }
}
@media screen and (max-width: 1400px) {
  #OurGallery h3 {
    font-size: 16px;
  }
  #OurGallery figure {
    padding: 0px !important;
  }
  #allcategoriesAndProductsControl button {
    margin-bottom: 10px;
  }
  #allcategoriesAndProductsControl figure figcaption h2, #allcategoriesAndProductsControl figure h3 {
    font-size: 22px !important;
  }
  .categoryProductsSize h3 {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1000px) {
  #nav2 ul li a {
    font-size: 19px;
  }
  #OurGallery h3 {
    font-size: 12px;
  }
  #OurGallery h2 {
    font-size: 40px;
  }
  #OurGallery figure {
    padding: 0px !important;
  }
  #OurGallery figure figcaption {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  #AboutUs h2 {
    font-size: 35px;
  }
  #AboutUs p {
    font-size: 16px;
  }
  #OurServices h2 {
    font-size: 40px;
  }
  #OurServices p {
    font-size: 16px;
  }
  #OurDatesJourney h2 {
    font-size: 35px;
  }
  #OurDatesJourney h3 {
    font-size: 30px;
  }
  #OurDatesJourney p {
    font-size: 16px;
  }
  #OurMissinAndOurVision h2 {
    font-size: 40px;
  }
  #OurMissinAndOurVision p {
    font-size: 16px;
  }
  #ContactUs h2 {
    font-size: 40px;
  }
  #ContactUs p {
    font-size: 16px;
  }
  #specificCategory h2 {
    font-size: 40px;
  }
  #specificCategory p {
    font-size: 16px;
  }
  #allcategoriesAndProductsControl .col-12 .controlTitles {
    font-size: 25px !important;
  }
  #allcategoriesAndProductsControl .col-3 .controlTitles {
    font-size: 25px !important;
  }
  #allcategoriesAndProductsControl button {
    font-size: 14px !important;
  }
  #nav1 ul li h6, #nav1 ul li a {
    font-size: 14px !important;
  }
  .categoryProductsSize h3 {
    font-size: 12px !important;
  }
  .categoryText h3 {
    font-size: 20px !important;
  }
  .categoryText h5 {
    font-size: 17px !important;
  }
  .col-3 .containerBox {
    padding: 0px !important;
  }
  .col-3 .containerBox figcaption {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 800px) {
  #AboutUs h2 {
    font-size: 25px;
  }
  #AboutUs p {
    font-size: 14px;
  }
  #allcategoriesAndProductsControl figure figcaption h2, #allcategoriesAndProductsControl figure h3 {
    font-size: 18px !important;
  }
  #allcategoriesAndProductsControl button {
    font-size: 12px !important;
  }
  #nav1 .navImage {
    width: 30%;
  }
  #nav1 ul li h6, #nav1 ul li a {
    font-size: 12px !important;
  }
  #nav2 ul li a {
    font-size: 8px;
  }
  .categoryProductsSize h3 {
    font-size: 8px !important;
  }
  .categoryText h3 {
    font-size: 14px !important;
  }
  .categoryText h5 {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 520px) {
  #OurGallery h3 {
    font-size: 8px;
  }
  #OurGallery h2 {
    font-size: 25px;
  }
  #OurServices h2 {
    font-size: 25px;
  }
  #OurServices p {
    font-size: 14px;
  }
  #OurDatesJourney h2 {
    font-size: 25px;
  }
  #OurDatesJourney h3 {
    font-size: 20px;
  }
  #OurDatesJourney p {
    font-size: 14px;
  }
  #OurMissinAndOurVision h2 {
    font-size: 22px;
  }
  #OurMissinAndOurVision p {
    font-size: 14px;
  }
  #ContactUs h2 {
    font-size: 25px;
  }
  #ContactUs p {
    font-size: 14px;
  }
  #specificCategory h2 {
    font-size: 25px;
  }
  #specificCategory p {
    font-size: 14px;
  }
  #allcategoriesAndProductsControl figure figcaption h2, #allcategoriesAndProductsControl figure h3 {
    font-size: 16px !important;
  }
  #allcategoriesAndProductsControl button {
    font-size: 8px !important;
  }
  #nav2 ul li a {
    font-size: 10px;
  }
  .categoryProductsSize h3 {
    font-size: 4px !important;
  }
  footer p {
    font-size: 8px !important;
  }
  footer .footerIcons {
    width: 30px !important;
    height: 30px !important;
    font-size: 10px !important;
  }
  .categoryText h3 {
    font-size: 8px !important;
  }
  .categoryText h5 {
    font-size: 6px !important;
  }
}/*# sourceMappingURL=style.css.map */